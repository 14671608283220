import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBonusStore } from 'web/src/modules/bonuses/store';
export default function useCBCBalance(props, emit) {
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const router = useRouter();
    const { showDialog, closeDialog } = useDialogs();
    const { currency } = useCurrency();
    const bonusStore = useBonusStore();
    let confirmModalId = '';
    let balanceSnapshot = '';
    const bonusCbc = toRef(()=>bonusStore.bonusCbc);
    const isShowBalanceCard = toRef(()=>!!bonusCbc.value || false);
    const progressProperties = computed(()=>({
            circleSize: CircleSize.SIZE_44,
            percentage: (bonusCbc.value?.progress ?? 0) * 100,
            iconSize: IconSize.SIZE_20
        }));
    const isProgressComplete = computed(()=>{
        const progress = bonusCbc.value?.progress;
        const walletBalanceNumber = bonusCbc.value?.walletBalanceNumber;
        return (progress ?? 0) >= 1 && 0 !== walletBalanceNumber;
    });
    const formattedBalance = computed(()=>formatMoney(bonusCbc.value?.walletBalanceNumber ?? 0, {
            currency: bonusCbc.value?.currency ?? currency.value,
            hideCurrency: false
        }));
    const balanceLabel = computed(()=>`${$translate('JSPACC_ACC_BALANCE').value} ${formattedBalance.value}`);
    function beforeUnmount() {
        if (confirmModalId) {
            closeDialog(confirmModalId);
            confirmModalId = '';
        }
    }
    function onClickCard() {
        emit('click-card');
        router.push({
            name: RouteName.BONUSES
        });
    }
    async function onWithdrawBonus() {
        closeDialog(confirmModalId);
        emit('withdraw-bonus', balanceSnapshot);
        await bonusStore.doWithdrawBonusCBC();
        await bonusStore.fetchBonusWagers();
    }
    function showCollectModal() {
        if (bonusCbc.value) {
            balanceSnapshot = `${formattedBalance.value}`;
            const { id, subscribe } = showDialog({
                presetName: PresetName.CONFIRM,
                options: {
                    title: $translate('WEB2_BONUS_OUTPUT').value,
                    confirmMessage: $translate('WEB2_BONUS_OUTPUT_QUESTION', toRef(()=>({
                            AMOUNT: formattedBalance.value
                        }))).value,
                    buttons: [
                        {
                            label: $translate('WEB2_MODAL_CONFIRM').value
                        }
                    ],
                    ...props.isInProfile ? {} : {
                        width: ModalWidth.SMALL,
                        isCentered: false
                    },
                    dataTestId: 'bonus-show-collect'
                }
            });
            confirmModalId = id;
            subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    onWithdrawBonus();
                }
            });
        }
    }
    return {
        isShowBalanceCard,
        progressProperties,
        isProgressComplete,
        formattedBalance,
        balanceLabel,
        beforeUnmount,
        onClickCard,
        showCollectModal
    };
}
