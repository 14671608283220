import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import useCustomerVerificationVideo from './Video/composables/useCustomerVerificationVideo';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationVideoCallTopBar',
    setup (__props) {
        const { onCloseVideoVerificationOnCall } = useCustomerVerificationVideo();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _ctx.$t('WEB2_VV_PAGE_TITLE'),
                "hide-prefix": ""
            }, {
                suffix: _withCtx(()=>[
                        _createVNode(VButton, {
                            "icon-name": _unref(IconName).CROSS,
                            "icon-size": _unref(IconSize).SIZE_24,
                            kind: _unref(ButtonKind).TRANSPARENT,
                            rounded: "",
                            onClick: _unref(onCloseVideoVerificationOnCall)
                        }, null, 8, [
                            "icon-name",
                            "icon-size",
                            "kind",
                            "onClick"
                        ])
                    ]),
                _: 1
            }, 8, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerVerificationVideoCallTopBar'
                ]
            ]);
        };
    }
});
