import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = [
    "title"
];
import { toRef } from 'vue';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PersonalInfo',
    setup (__props) {
        const customerData = useCustomerDataStore();
        const fullName = toRef(customerData, 'fullName');
        const login = toRef(customerData, 'login');
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _createCommentVNode("", true),
                _createCommentVNode("", true),
                (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    title: fullName.value,
                    class: _normalizeClass(_ctx.$style['personal_info__title'])
                }, _toDisplayString(fullName.value), 11, _hoisted_1)),
                (_openBlock(), _createElementBlock("span", {
                    key: 3,
                    class: _normalizeClass(_ctx.$style['personal_info__subtitle']),
                    onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$copyToClipboard({
                            data: login.value,
                            isProfileModalTooltip: true
                        }))
                }, _toDisplayString(`${_ctx.$t('WEB2_ACCOUNT')}: ${login.value}`), 3))
            ], 64));
    }
});
