import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VForm } from 'web/src/components/Form';
import { VLoaderDelayed } from 'web/src/components/Loader';
import useSetEmailForm from 'web/src/modules/profile/pages/CustomerSubscriptionsRoutePage/composables/useSetEmailForm';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SetEmailForm',
    setup (__props, param) {
        let { expose: __expose } = param;
        const { form, isFormLoaded, isSubmitPending, customErrors, emailOnlySchema, emailOnlyUiSchema, onSubmit } = useSetEmailForm();
        __expose({
            submit: ()=>{
                form.value?.submit();
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(isFormLoaded) && _unref(emailOnlySchema) && _unref(emailOnlyUiSchema) ? (_openBlock(), _createBlock(_unref(VForm), {
                    key: 0,
                    ref_key: "form",
                    ref: form,
                    "is-from-modal": "",
                    "custom-errors": _unref(customErrors),
                    schema: _unref(emailOnlySchema),
                    "ui-schema": _unref(emailOnlyUiSchema),
                    "is-pending": _unref(isSubmitPending),
                    onSubmit: _unref(onSubmit)
                }, null, 8, [
                    "custom-errors",
                    "schema",
                    "ui-schema",
                    "is-pending",
                    "onSubmit"
                ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 1
                }))
            ])), [
                [
                    _directive_auto_id,
                    'SetEmailForm'
                ]
            ]);
        };
    }
});
