import { ButtonActionType } from 'web/src/modules/profile/submodules/bonuses/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function getPlayButton(isGameDetailsLoading, $t) {
    return {
        id: ButtonActionType.PLAY,
        label: $t('WEB2_BONUSES_PLAY_BUTTON').value,
        kind: ButtonKind.PRIMARY,
        isLoading: isGameDetailsLoading
    };
}
