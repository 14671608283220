import getUsedCount from './getUsedCount';
import getCount from './getCount';
import getBalance from './getBalance';
export default function getBalanceProperties(props, $t, $formatMoney) {
    return {
        titleLeft: $t('WEB2_BONUS_BALANCE_FREESPIN_TITLE').value,
        valueLeft: $formatMoney(getBalance(props)),
        titleRight: $t('WEB2_BONUSES_AMOUNT_TITLE').value,
        valueRight: `${getUsedCount(props).toString()}/${getCount(props).toString()}`
    };
}
