import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { toRef } from 'vue';
import { useVideoVerificationStore } from 'web/src/modules/verification/submodules/video-verification/store';
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationVideoCall',
    setup (__props) {
        const verificationStore = useVideoVerificationStore();
        const upcomingMeetingRoomUrl = toRef(verificationStore, 'getUpcomingMeetingRoomUrl');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return upcomingMeetingRoomUrl.value ? _withDirectives((_openBlock(), _createBlock(VIframe, {
                key: 0,
                src: upcomingMeetingRoomUrl.value,
                "show-loader": "",
                "flex-layout": "",
                "full-size": "",
                "hide-title": "",
                allow: "fullscreen; camera; microphone",
                name: "videoVerification",
                class: _normalizeClass(_ctx.$style['v-iframe-vv'])
            }, null, 8, [
                "src",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerVerificationVideoCall'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
