import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { ButtonActionType } from 'web/src/modules/profile/submodules/bonuses/enums';
import useNavigationToGameDetails from 'web/src/modules/profile/submodules/bonuses/controllers/composables/useNavigationToGameDetails';
import { getAvailableBefore, getBalance, getBalanceProperties, getBonusButtons, getLayoutProperties } from './utils';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ export default function useProfileBonusesFreespinController(props, emit) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const router = useRouter();
    const { routeToGameDetails, isGameDetailsLoading } = useNavigationToGameDetails(router);
    const availableBefore = computed(()=>getAvailableBefore(props));
    const balance = computed(()=>getBalance(props));
    const balanceProperties = computed(()=>getBalanceProperties(props, $translate, $formatMoney));
    const bonusButtons = computed(()=>getBonusButtons(props, $translate, isGameDetailsLoading.value));
    const layoutProperties = computed(()=>getLayoutProperties(props));
    const refuseBonusEmit = ()=>{
        emit('refuse', props.bonus);
    };
    const collectBonusEmit = ()=>{
        emit('collect', {
            bonus: props.bonus,
            balance: balance.value
        });
    };
    const emitTimerEnd = ()=>{
        emit('timer-end');
    };
    const handleButtonClick = (id)=>{
        switch(id){
            case ButtonActionType.PLAY:
                if (props.bonus.freespin?.provider) routeToGameDetails(props.bonus);
                else router.push({
                    name: CasinoRouteName.CASINO_LOBBY
                });
                break;
            case ButtonActionType.REFUSE:
                refuseBonusEmit();
                break;
            case ButtonActionType.COLLECT:
                collectBonusEmit();
                break;
            default:
                break;
        }
    };
    return {
        availableBefore,
        balanceProperties,
        bonusButtons,
        layoutProperties,
        handleButtonClick,
        emitTimerEnd
    };
}
