import { AvailableBonusType } from '@leon-hub/api-sdk';
const getBonusBalance = (bonus)=>{
    const bonusType = bonus?.availableBonusType;
    switch(bonusType){
        case AvailableBonusType.FREEBET:
            return bonus?.freebet?.amount || 0;
        case AvailableBonusType.MAX_BONUS_AMOUNT:
            return bonus?.maxBonusAmount || 0;
        case AvailableBonusType.BONUS_AMOUNT:
            return bonus?.bonusAmount || 0;
        case AvailableBonusType.FREESPIN:
            return bonus?.freespin?.nominal || 0;
        default:
            return 0;
    }
};
export default getBonusBalance;
