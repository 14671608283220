import { buttonDisabledLoadingState } from 'web/src/modules/profile/submodules/bonuses/utils';
import getPlayButton from './getPlayButton';
import getCollectButton from './getCollectButton';
import getRefuseButton from './getRefuseButton';
export default function getBonusButtons(props, $t, isGameDetailsLoading) {
    let buttons = [];
    if (props.bonus?.freespin?.nominal) buttons = [
        getPlayButton(isGameDetailsLoading, $t)
    ];
    if (props.bonus?.transferAmount) buttons = [
        ...buttons,
        getCollectButton($t)
    ];
    if (!props.bonus?.transferAmount && !!props.bonus?.refuseAvailable) buttons = [
        ...buttons,
        getRefuseButton($t)
    ];
    if (props.activeButton) buttons = buttons.map((button)=>({
            ...button,
            ...buttonDisabledLoadingState(props.activeButton, {
                id: props.bonus.bonusId,
                type: button.id || ''
            })
        }));
    return buttons;
}
