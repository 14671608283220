import { IconName } from '@leon-hub/icons';
import { ButtonActionType } from 'web/src/modules/profile/submodules/bonuses/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function getCollectButton($t) {
    return {
        id: ButtonActionType.COLLECT,
        label: $t('WEB2_BONUSES_COLLECT_BUTTON').value,
        kind: ButtonKind.PRIMARY,
        iconName: IconName.CHECK_FILLED,
        iconRight: true
    };
}
