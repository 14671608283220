import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class ProfileBonusModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addProfileRoute({
            name: RouteName.BONUSES,
            path: '/bonuses',
            component: ()=>import('./views/ProfileBonusesRouteComponent/ProfileBonusesRouteComponent.vue'),
            props: true,
            topBar: ()=>import('./views/ProfileBonusesTopBarRouteComponent/ProfileBonusesTopBarRouteComponent.vue'),
            title: '{{$t(\'WEB2_BONUSES_TITLE\')}}',
            prefetch: ()=>import('./prefetch/ProfileBonusesPagePrefetch')
        });
        router.addProfileRoute({
            name: RouteName.BONUS_CODE_ENTER,
            path: '/bonus-code-enter',
            component: ()=>import('./layouts/BonusCodeInputRouteComponent/BonusCodeInputRouteComponent.vue'),
            title: '{{$t(\'WEB2_ENTER_BONUS_CODE_TITLE\')}}',
            topBarProps: {
                hidePrefix: true
            }
        });
        router.addProfileRoute({
            name: RouteName.BONUS_WITHDRAWALS,
            path: '/bonus-withdrawals',
            component: ()=>import('./pages/BonusWithdrawalsRoutePage/BonusWithdrawalsRoutePage.vue'),
            title: '{{$t(\'JSP_ENCASH_MOBILE_TITLE\')}}'
        });
    }
}
