import { getBonusTermsLink } from 'web/src/modules/profile/submodules/bonuses/utils';
import getTotalCount from './getTotalCount';
export default function getLayoutProperties(props) {
    return {
        bonusTitle: props.bonus?.campaignName || '',
        stepCount: props.bonus?.stepCount,
        stepNumber: props.bonus?.stepNumber,
        termsLink: getBonusTermsLink(props.bonus),
        bonusBadgeText: getTotalCount(props)
    };
}
