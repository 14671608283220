import { useRouter } from 'vue-router';
import { computed, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import useResponsibleGamblingStoreV2 from 'web/src/modules/profile/submodules/responsible-gambling/store/useResponsibleGamblingStoreV2';
import { useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useResponsibleGamblingTopBar(props) {
    const router = useRouter();
    const responsibleGamblingStoreV2 = useResponsibleGamblingStoreV2();
    const financeStore = useCustomerFinanceStore();
    const formatMoney = useFormatMoney();
    toRef(responsibleGamblingStoreV2, 'betStep');
    toRef(responsibleGamblingStoreV2, 'depositStep');
    const balance = toRef(financeStore, 'balance');
    const balanceValue = computed(()=>formatMoney(Number(balance.value || 0)));
    const infoButtonProperties = {
        kind: ButtonKind.NAV,
        iconName: IconName.INFO_OUTLINE,
        iconSize: IconSize.SIZE_24
    };
    const closeButtonProperties = {
        kind: ButtonKind.NAV,
        iconName: IconName.CROSS,
        iconSize: IconSize.SIZE_24
    };
    const isLightBackground = computed(()=>false);
    function onClick() {
        if (props.isBetLimit) router.push({
            name: RouteName.RESPONSIBLE_GAMBLING_BET_LIMIT_INFO
        });
        else if (props.isDepositLimit) router.push({
            name: RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT_INFO
        });
    }
    function onClose() {
        router.closeModal();
    }
    return {
        infoButtonProperties,
        closeButtonProperties,
        isLightBackground,
        balanceValue,
        onClick,
        onClose
    };
}
