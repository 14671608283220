import RouteName from '@leon-hub/routing-config-names';
const getBonusTermsLink = (bonus)=>{
    if (!bonus.categoryId || !bonus.actionUrl) {
        if (bonus.termsKey) return {
            name: RouteName.CMS_PROMO_TERMS,
            params: {
                cmsKey: bonus.termsKey
            }
        };
        return;
    }
    return {
        name: RouteName.PROMOTION_DETAILS,
        params: {
            categoryId: bonus.categoryId,
            actionUrl: bonus.actionUrl
        },
        query: {
            scrollToTerms: 'true'
        }
    };
};
export default getBonusTermsLink;
