import { computed, ref, toRef } from 'vue';
import { CustomerFieldType } from '@leon-hub/api-sdk';
import useSimpleRegistrantProfileStore from 'web/src/modules/profile/store/useSimpleRegistrantProfileStore';
import CustomerFormError from 'web/src/utils/forms/CustomerFormError';
export default function useSetEmailForm() {
    const customErrors = ref({});
    const isSubmitPending = ref(false);
    const form = ref();
    const simpleRegistrantProfileStore = useSimpleRegistrantProfileStore();
    const isFormLoaded = toRef(simpleRegistrantProfileStore, 'isFormLoaded');
    const profileSchema = toRef(simpleRegistrantProfileStore, 'profileSchema');
    const emailOnlySchema = computed(()=>{
        if (!profileSchema.value) return null;
        return {
            ...profileSchema.value.schema,
            required: [
                CustomerFieldType.EMAIL
            ]
        };
    });
    const emailOnlyUiSchema = computed(()=>{
        if (!profileSchema.value) return null;
        if (!profileSchema.value.uiSchema.fields) return null;
        const fieldsCopy = JSON.parse(JSON.stringify(profileSchema.value.uiSchema.fields));
        const keys = Object.keys(fieldsCopy);
        for (const key of keys)if (key !== CustomerFieldType.EMAIL) {
            fieldsCopy[key].hidden = true;
            fieldsCopy[key].disabled = true;
        }
        return {
            ...profileSchema.value.uiSchema,
            fields: fieldsCopy,
            submitButton: null
        };
    });
    function resetCustomErrors() {
        for (const fieldId of Object.keys(customErrors.value)){
            const errors = customErrors.value[fieldId];
            for (const error of errors)Object.assign(error, {
                message: ''
            });
        }
    }
    async function onSimpleRegistrantUpdate(param) {
        let { formData } = param;
        isSubmitPending.value = true;
        resetCustomErrors();
        try {
            await simpleRegistrantProfileStore.updateCustomerData({
                formData
            });
            isSubmitPending.value = false;
        } catch (error) {
            if (error instanceof CustomerFormError) {
                isSubmitPending.value = false;
                customErrors.value = error.customErrors;
            }
            throw error;
        }
    }
    function onSubmit(data) {
        if (!data.errors) onSimpleRegistrantUpdate({
            formData: data.formData
        });
    }
    return {
        form,
        customErrors,
        isSubmitPending,
        isFormLoaded,
        emailOnlySchema,
        emailOnlyUiSchema,
        resetCustomErrors,
        onSimpleRegistrantUpdate,
        onSubmit
    };
}
