import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import BalanceCard from 'web/src/modules/profile/components/BalanceCard/BalanceCard.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import BalanceValue from 'web/src/modules/profile/components/BalanceValue/BalanceValue.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import { useCashbackBalance } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CashbackBalance',
    setup (__props) {
        const { formattedBalance, isShowBalance, cashbackBalanceClick } = useCashbackBalance();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isShowBalance) ? _withDirectives((_openBlock(), _createBlock(BalanceCard, {
                key: 0,
                mode: _unref(BalanceMode).Multiple,
                class: "cashback-balance",
                onClick: _unref(cashbackBalanceClick)
            }, {
                default: _withCtx(()=>[
                        _createVNode(VButton, {
                            label: "",
                            kind: _unref(ButtonKind).SECONDARY,
                            "icon-name": _unref(IconName).BONUSES,
                            "icon-size": _unref(IconSize).SIZE_24,
                            rounded: ""
                        }, null, 8, [
                            "kind",
                            "icon-name",
                            "icon-size"
                        ]),
                        _createVNode(BalanceValue, {
                            value: _unref(formattedBalance),
                            caption: _ctx.$t('WEB2_PROFILE_CASHBACK_BALANCE_CAPTION')
                        }, null, 8, [
                            "value",
                            "caption"
                        ])
                    ]),
                _: 1
            }, 8, [
                "mode",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'CashbackBalance'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
