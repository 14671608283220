import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "vv_select-time"
};
const _hoisted_2 = {
    class: "vv_select-time__wrapper"
};
const _hoisted_3 = {
    class: "vv_select-time__hint-text"
};
const _hoisted_4 = {
    class: "vv_select-time__title"
};
const _hoisted_5 = {
    class: "vv_select-time__tabs"
};
const _hoisted_6 = {
    class: "vv_select-time__footer"
};
import { computed, nextTick, onBeforeMount, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { logger } from '@leon-hub/logging';
import { IconName } from '@leon-hub/icons';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { VideoVerificationStatus } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { VLoader } from '@components/loader';
import { VScrollbar } from '@components/v-scrollbar';
import { useI18n } from 'web/src/modules/i18n/composables';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VRadio from 'web/src/components/Radio/VRadio/VRadio.vue';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useVideoVerificationStore } from 'web/src/modules/verification/submodules/video-verification/store';
import { nameDayWithDate } from 'web/src/modules/verification/submodules/video-verification/utils';
import { useUserStore } from 'web/src/modules/user/store';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import HintBlockKind from 'web/src/components/HintBlock/VHintBlock/enums/HintBlockKind';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import CustomerVerificationCardTimer from 'web/src/modules/profile/submodules/verification/components/CardTimer/CustomerVerificationCardTimer.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationSelectTimeRoute',
    setup (__props) {
        const { $translate } = useI18n();
        const router = useRouter();
        const verificationStore = useVideoVerificationStore();
        const { loadUserData } = useUserStore();
        const { showDialog } = useDialogs();
        const tabs = ref(null);
        const activeTab = ref('');
        const activeTimeId = ref(0);
        const loading = ref(true);
        const isShowHint = ref(true);
        const isTimeChangeAvailable = toRef(verificationStore, 'isTimeChangeAvailable');
        const availableSlots = toRef(verificationStore, 'availableSlots');
        const customerDataStore = useCustomerDataStore();
        const videoVerificationStatusExpiration = toRef(customerDataStore, 'videoVerificationStatusExpiration');
        const videoVerificationStatus = toRef(customerDataStore, 'videoVerificationStatus');
        const isChangeTimeView = computed(()=>{
            if (videoVerificationStatus.value && isTimeChangeAvailable.value) return videoVerificationStatus.value === VideoVerificationStatus.SCHEDULED;
            return false;
        });
        const activeSlot = computed(()=>availableSlots.value.get(activeTab.value));
        const tabsProperties = computed(()=>{
            const items = Array.from(availableSlots.value.keys()).map((value)=>({
                    id: value,
                    label: nameDayWithDate(value, $translate)
                })) || [];
            return {
                items,
                activeId: activeTab.value,
                type: TabsType.NORMAL
            };
        });
        const buttonPropertiesNext = computed(()=>({
                label: $translate('WEB2_CONTINUE').value,
                ...activeTimeId.value ? {
                    iconName: IconName.CHECK_FILLED
                } : null,
                kind: ButtonKind.PRIMARY,
                fullWidth: true
            }));
        const buttonPropertiesCancel = computed(()=>({
                label: $translate('WEB2_MOBILE_CANCEL').value,
                kind: ButtonKind.TRANSPARENT,
                fullWidth: true
            }));
        async function onTabClick(id) {
            activeTab.value = id;
            await nextTick();
            if (tabs.value) tabs.value.slideToActive();
        }
        function onClickTime(id) {
            activeTimeId.value = id;
        }
        async function onClickNext() {
            try {
                loading.value = true;
                await verificationStore.saveScheduleMeeting(activeTimeId.value);
                await verificationStore.syncUpcomingMeeting();
                await loadUserData();
                router.back();
            } catch (error) {
                if (error instanceof GqlApiBatchedSubRequestError && error.code.equals('MEETING_SLOT_IS_OCCUPIED')) {
                    activeTimeId.value = 0;
                    showDialog({
                        presetName: PresetName.ALERT_WARNING,
                        options: {
                            title: $translate('WEB2_VV_TIME_BUSY_MODAL_TITLE').value,
                            confirmMessage: $translate('WEB2_VV_TIME_BUSY_MODAL_TEXT').value,
                            buttons: [
                                {
                                    label: $translate('WEB2_CLOSE').value
                                }
                            ],
                            dataTestId: 'vv-time-busy'
                        }
                    });
                    await verificationStore.syncSlots();
                }
                logger.error('refuseMeeting', error);
            } finally{
                loading.value = false;
            }
        }
        const hintBlockProperties = {
            kind: HintBlockKind.DEFAULT,
            labelAccept: $translate('WEB2_VV_SELECT_TIME_PAGE_HINT_BTN').value
        };
        async function onEndTime() {
            await loadUserData();
            if (!(videoVerificationStatus.value === VideoVerificationStatus.INVITED || videoVerificationStatus.value === VideoVerificationStatus.NO_ANSWER)) router.push({
                name: RouteName.CUSTOMER_VERIFICATION
            });
        }
        onBeforeMount(async ()=>{
            await verificationStore.syncUpcomingMeeting();
            await verificationStore.syncSlots();
            await loadUserData();
            loading.value = false;
            isShowHint.value = isChangeTimeView.value;
            const keys = Array.from(availableSlots.value.keys());
            if (keys.length) // eslint-disable-next-line prefer-destructuring
            activeTab.value = keys[0];
        });
        return (_ctx, _cache)=>loading.value ? (_openBlock(), _createBlock(_unref(VLoader), {
                key: 0
            })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    isTimeChangeAvailable.value && isShowHint.value ? (_openBlock(), _createBlock(VHintBlock, _mergeProps({
                        key: 0
                    }, hintBlockProperties, {
                        onOnAccept: _cache[0] || (_cache[0] = ($event)=>isShowHint.value = false)
                    }), {
                        default: _withCtx(()=>[
                                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('WEB2_VV_SELECT_TIME_PAGE_HINT')), 1)
                            ]),
                        _: 1
                    }, 16)) : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('WEB2_VV_SELECT_TIME_PAGE_TITLE')), 1),
                    _createElementVNode("div", _hoisted_5, [
                        _createVNode(VTabs, _mergeProps({
                            ref_key: "tabs",
                            ref: tabs
                        }, tabsProperties.value, {
                            onTabClick: onTabClick
                        }), null, 16)
                    ]),
                    _createVNode(_unref(VScrollbar), {
                        "flex-fill": "",
                        class: "vv_select-time__time"
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeSlot.value, (slot)=>(_openBlock(), _createBlock(VRadio, {
                                        key: slot.id,
                                        value: slot.id,
                                        label: `${slot.start}-${slot.end}`,
                                        checked: slot.id === activeTimeId.value,
                                        onClick: ($event)=>onClickTime(slot.id)
                                    }, null, 8, [
                                        "value",
                                        "label",
                                        "checked",
                                        "onClick"
                                    ]))), 128))
                            ]),
                        _: 1
                    }),
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(VButton, _mergeProps(buttonPropertiesNext.value, {
                            disabled: !activeTimeId.value,
                            onClick: onClickNext
                        }), null, 16, [
                            "disabled"
                        ]),
                        videoVerificationStatusExpiration.value && !isChangeTimeView.value ? (_openBlock(), _createBlock(CustomerVerificationCardTimer, {
                            key: 0,
                            "expiration-date": videoVerificationStatusExpiration.value,
                            onTimerEnd: onEndTime
                        }, null, 8, [
                            "expiration-date"
                        ])) : _createCommentVNode("", true),
                        isChangeTimeView.value ? (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 1
                        }, buttonPropertiesCancel.value, {
                            class: "vv_select-time__btn-second",
                            onClick: _cache[1] || (_cache[1] = ($event)=>_ctx.$router.back())
                        }), null, 16)) : _createCommentVNode("", true)
                    ])
                ])
            ]));
    }
});
