import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import ProfileBonusesCardLayout from '../../components/ProfileBonusesCardLayout/ProfileBonusesCardLayout.vue';
import ProfileBonusesCardTimer from '../../components/ProfileBonusesCardTimer/ProfileBonusesCardTimer.vue';
import ProfileBonusesCardButtonController from '../ProfileBonusesCardButtonController/ProfileBonusesCardButtonController.vue';
import ProfileBonusesCardDescriptionColumns from '../../components/ProfileBonusesCardDescriptionColumns/ProfileBonusesCardDescriptionColumns.vue';
import { useProfileBonusesFreespinController } from './composables';
// eslint-disable-next-line max-len
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesFreespinController',
    props: {
        bonus: {},
        activeButton: {}
    },
    emits: [
        "refuse",
        "collect",
        "timer-end"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { availableBefore, balanceProperties, bonusButtons, layoutProperties, handleButtonClick, emitTimerEnd } = useProfileBonusesFreespinController(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ProfileBonusesCardLayout, _normalizeProps(_guardReactiveProps(_unref(layoutProperties))), {
                default: _withCtx(()=>[
                        _createVNode(ProfileBonusesCardDescriptionColumns, _normalizeProps(_guardReactiveProps(_unref(balanceProperties))), null, 16),
                        _createVNode(ProfileBonusesCardTimer, {
                            "expiration-date": _unref(availableBefore),
                            onTimerEnd: _unref(emitTimerEnd)
                        }, null, 8, [
                            "expiration-date",
                            "onTimerEnd"
                        ]),
                        _createVNode(ProfileBonusesCardButtonController, {
                            buttons: _unref(bonusButtons),
                            "has-columns": _unref(bonusButtons).length > 1,
                            onClick: _unref(handleButtonClick)
                        }, null, 8, [
                            "buttons",
                            "has-columns",
                            "onClick"
                        ])
                    ]),
                _: 1
            }, 16)), [
                [
                    _directive_auto_id,
                    'ProfileBonusesFreespinController'
                ]
            ]);
        };
    }
});
