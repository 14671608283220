import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { onBeforeMount, onBeforeUnmount, toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import BalanceCard from 'web/src/modules/profile/components/BalanceCard/BalanceCard.vue';
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import BalanceValue from 'web/src/modules/profile/components/BalanceValue/BalanceValue.vue';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useCBCBalance } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CBCBalance',
    props: {
        mode: {
            default: BalanceMode.Single
        },
        isInProfile: {
            type: Boolean
        }
    },
    emits: [
        "withdraw-bonus",
        "click-card"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isShowBalanceCard, progressProperties, isProgressComplete, formattedBalance, balanceLabel, beforeUnmount, onClickCard, showCollectModal } = useCBCBalance(props, emit);
        const profileBonusesStore = useProfileBonusesStore();
        const egsBonuses = toRef(()=>profileBonusesStore.egsBonuses);
        onBeforeMount(async ()=>{
            await profileBonusesStore.fetchBonuses();
        });
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isShowBalanceCard) ? _withDirectives((_openBlock(), _createBlock(BalanceCard, {
                key: 0,
                mode: _unref(BalanceMode).Multiple,
                class: _normalizeClass(_ctx.$style['cbc-balance']),
                onClick: _unref(onClickCard)
            }, {
                default: _withCtx(()=>[
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['cbc-balance__wrapper'])
                            }, [
                                _unref(isProgressComplete) ? (_openBlock(), _createBlock(VButton, {
                                    key: 1,
                                    label: _ctx.$t('WEB2_GRAB'),
                                    onClick: _withModifiers(_unref(showCollectModal), [
                                        "stop"
                                    ])
                                }, null, 8, [
                                    "label",
                                    "onClick"
                                ])) : (_openBlock(), _createBlock(VCircularProgress, _mergeProps({
                                    key: 0
                                }, _unref(progressProperties), {
                                    class: "progress"
                                }), null, 16)),
                                egsBonuses.value?.length ? (_openBlock(), _createBlock(VBadge, {
                                    key: 2,
                                    label: `${egsBonuses.value?.length}`,
                                    class: _normalizeClass(_ctx.$style['balance-card__count'])
                                }, null, 8, [
                                    "label",
                                    "class"
                                ])) : _createCommentVNode("", true)
                            ], 2),
                            _createVNode(BalanceValue, {
                                value: _unref(formattedBalance),
                                caption: _ctx.$t('WEB2_CBC_BALANCE'),
                                "balance-label": _unref(balanceLabel)
                            }, null, 8, [
                                "value",
                                "caption",
                                "balance-label"
                            ])
                        ], 64))
                    ]),
                _: 1
            }, 8, [
                "mode",
                "class",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'CBCBalance'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
